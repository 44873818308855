import { configureStore } from "@reduxjs/toolkit";
import userAuthSlice from "./slice/userAuthSlice";
import bannerSlice from "./slice/bannerSlice";
import dialogSlice from "./slice/dialogSlice";
import categorySlice from "./slice/categorySlice";
import extraSlice from "./slice/extraSlice";

// Enable Redux DevTools Extension

const store = configureStore({
  reducer: {
    userAuth: userAuthSlice,
    dialogue: dialogSlice,
    extra: extraSlice,
    banner: bannerSlice,
    category:categorySlice,
  },
});

export default store;
