import React from "react";
import Ceo1 from "../../assets/images/ceo1.png";
import Ceo2 from "../../assets/images/ceo2.png";
import Ceo3 from "../../assets/images/ceo3.png";
import Title, { Description, SmallTitle } from "../extra/Title";

const CeoDetails = () => {
  return (
    <section id="CeoDetails" className="bg-light p100-md-y p40-y">
      <div className="container">
        <div className="startCeoDetails">
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-8 col-smm-10 col-12">
              <Title
                mainTitle={`Professional Team Members`}
                titleDec={`At Multikod Infosys & Advance Training, our success is driven by the exceptional talent and dedication of our professional team members. We take pride in assembling a diverse group of experts who bring a wealth of experience and passion to every project. Get to know the individuals who make up our dynamic team`}
                className={`text-center text-overflow-3`}
              />
            </div>
          </div>
          <div className="row justify-content-center m40-top">
            <CeoList
              ceoImage={Ceo1}
              ceoName={`Meet V. Anghan`}
              ceoPosition={`Co-Founder`}
              ceoNumber={`+91 97234 24438`}
              className={`order-lg-0 order-1`}
            />
            <CeoList
              ceoImage={Ceo2}
              ceoName={`Madhur S. Kathrotiya`}
              ceoPosition={`CEO & Founder`}
              ceoNumber={`+91 63581 63236`}
              className={`order-lg-1`}
            />
            <CeoList
              ceoImage={Ceo3}
              ceoName={`Kartik M. Gajera`}
              ceoPosition={`Co-Founder`}
              ceoNumber={`+91 78620 28231`}
              className={`order-2`}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default CeoDetails;

export const CeoList = ({
  ceoImage,
  ceoName,
  ceoPosition,
  ceoNumber,
  className,
}) => {
  return (
    <div
      className={`col-lg-4 col-smm-6 col-xsm-9 col-mini-10 col-12 m30-bottom ${className}`}
    >
      <div className="ceoList">
        <div className="ceoImage height-xxl-510 height-lg-430 height-smm-310 height-330 overflow-hidden">
          <img src={ceoImage} alt="" height={`100%`} className="trans-3" />
        </div>
        <div className="ceoText p20 text-center">
          <SmallTitle className={`ceoName m15-y`} text={ceoName} />
          <Description
            text={ceoPosition}
            className={`ceoPosition text-gray p10-bottom m10-bottom border-bottom-lightGray2-1`}
          />
          <div className="ceoNumber text-gray fs-md-15 fs-13">
            <span>
              <i class="fa-solid fa-phone"></i>
            </span>
            <span className="m10-left fs-md-15 fs-13">{ceoNumber}</span>
          </div>
        </div>
      </div>
    </div>
  );
};
