import React from "react";
import Title, { Description, SmallTitle } from "../extra/Title";
import Course1 from "../../assets/images/course1.png";
import Course2 from "../../assets/images/course2.png";
import Course3 from "../../assets/images/course3.png";
import CourseIcon1 from "../../assets/images/courseIcon1.png";
import CourseIcon2 from "../../assets/images/courseIcon2.png";
import CourseIcon3 from "../../assets/images/courseIcon3.png";
import Button from "../extra/Button";

const OurCourses = () => {
  return (
    <section id="OurCourses" className="p100-md-y p40-y bg-light">
      <div className="container">
        <div className="startOurCourses">
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-8 col-smm-10 col-12">
              <Title
                mainTitle={`OUR OFFERED COURSES`}
                titleDec={`Embark on a transformative educational journey with our diverse array of meticulously crafted courses tailored to meet the evolving demands of the professional landscape. `}
                className={` text-center`}
              />
            </div>
          </div>
          <div className="row m40-top justify-content-center">
            <CourseBlog
              icon={CourseIcon1}
              image={Course1}
              title={`Full-Stack Development`}
              dec={`Embarking on the journey of Full Stack Development is like unlocking the door to a world of endless possibilities. In this blog, we'll explore the ins and outs of Full Stack Development, from understanding the concept to the skills required and the evolving landscape of this dynamic field`}
            />
            <CourseBlog
              icon={CourseIcon2}
              image={Course2}
              title={`Web Development`}
              dec={`In the vast realm of web development, the journey from conceptualization to creation is both thrilling and challenging. By mastering the foundational languages, exploring powerful frameworks, and adhering to best practices, developers can craft immersive web experiences that captivate users and stand the test of time. Whether you're a beginner taking the first steps or an experienced developer seeking to expand your skill set, the world of web development invites you to dive in and shape the digital landscape.`}
            />
            <CourseBlog
              icon={CourseIcon3}
              image={Course3}
              title={`Web Design`}
              dec={`In the vast landscape of the internet, web design serves as the artistic cornerstone that shapes our digital experiences. As technology continues to evolve, so does the field of web design. This blog will explore the intricacies of web design, from its foundational principles to the latest trends shaping the online world.`}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurCourses;

export const CourseBlog = ({ icon, image, title, dec }) => {
  return (
    <div className="col-lg-4 col-smm-6 col-xsm-9 col-mini-10 col-12 m20-bottom">
      <div className="thisCourse">
        <div className="courseImg">
          <div className="courseImage height-xl-300 height-md-230 height-180">
            <img src={image} alt="" className="h-100" />
          </div>
        </div>
        <div className="courseText p25-xxl p20-md p14 bg-light">
          <div className="courseIcon hw-md-55 hw-sm-45 hw-40 bg-light midBox">
            <img src={icon} alt="" />
          </div>
          <SmallTitle
            text={title}
            className={`courseTitle text-overflow-2 m10-bottom m5-top`}
          />
          <Description
            text={dec}
            className={`courseDec text-gray text-overflow-3`}
          />
          <div className="courseBtn">
            <Button
              className={`primeBtn m20-sm-top m10-top`}
              text={`Read More`}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
