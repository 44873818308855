import React from "react";
import Blog1 from "../../assets/images/blog1.webp";
import Blog2 from "../../assets/images/blog2.webp";
import Blog3 from "../../assets/images/blog3.webp";
import Title, { Description, SmallTitle } from "../extra/Title";
import blogUser from "../../assets/images/blogUser.jpg";

const Blog = () => {
  return (
    <section id="Blog" className="bg-light p100-top p150-bottom">
      <div className="container">
        <div className="startBlog">
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-8 col-smm-10 col-12">
              <Title
                mainTitle={`Latest News & Articles`}
                titleDec={`Welcome to the Latest News & Articles section of Multikod Infosys & Advance Training. Stay informed and inspired with our curated collection of news, insights, and thought-provoking articles covering the latest trends, innovations, and industry developments.`}
                className={` text-center`}
              />
            </div>
          </div>
          <div className="row m40-top justify-content-center">
            <SingleBlog
              image={Blog1}
              title={`Development Innovations`}
              dec={`Stay informed about the latest tools, frameworks, and development methodologies. Dive into articles that explore the cutting-edge technologies transforming the web development landscape, from front-end frameworks to server-side languages`}
              date={`February 1, 2024`}
              blogUserImg={blogUser}
              blogUserName={`Mr.Madhur`}
              blogUserPosition={`Admin`}
            />
            <SingleBlog
              image={Blog2}
              title={`E-commerce and Online Presence`}
              dec={`Navigate the complexities of e-commerce and online presence with insights into effective strategies, trends, and success stories. Learn how to create compelling online experiences that drive conversions and user satisfaction.`}
              date={`January 15, 2024`}
              blogUserImg={blogUser}
              blogUserName={`Mr.Madhur`}
              blogUserPosition={`Admin`}
            />
            <SingleBlog
              image={Blog3}
              title={`Security and Performance`}
              dec={`Prioritize the security and performance of your websites. Our articles cover the latest in web security practices, performance optimization techniques, and tools to ensure your websites are robust and reliable.`}
              date={`December 29, 2023`}
              blogUserImg={blogUser}
              blogUserName={`Mr.Madhur`}
              blogUserPosition={`Admin`}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Blog;

export const SingleBlog = ({
  date,
  image,
  title,
  dec,
  blogUserImg,
  blogUserName,
  blogUserPosition,
}) => {
  return (
    <div className=" col-lg-4 col-smm-6 col-xsm-9 col-mini-10 col-12 m20-bottom">
      <div className="thisBlog h-100">
        <div className="blogImg">
          <div className="blogImage height-xl-300 height-md-230 height-180">
            <img src={image} alt="" className="h-100" />
          </div>
        </div>
        <div className="blogText p25-xxl p20-md p14 bg-light">
          <Description
            text={date}
            className={`blogDate text-light text-overflow-1`}
          />
          <SmallTitle
            text={title}
            className={`blogTitle text-overflow-2 m10-bottom m5-top`}
          />
          <Description
            text={dec}
            className={`blogDec text-gray text-overflow-4`}
          />
          <div className="blogUser m30-top d-flex align-items-center">
            <div className="blogUserImage rounded-5 overflow-hidden hw-lg-40 hw-35 m10-right">
              <img src={blogUserImg} alt="" />
            </div>
            <div className="blogUserDetails">
              <div className="blogUserName text-second fw-bold fs-md-16 fs-sm-14 fs-12">
                {blogUserName}
              </div>
              <Description
                text={blogUserPosition}
                className={`blogDec text-gray text-overflow-3`}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
