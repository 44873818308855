import React, { useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Core1 from "../../../assets/images/core1.png";
import Core2 from "../../../assets/images/core2.png";
import Core3 from "../../../assets/images/core3.png";
import { Description, SmallTitle } from "../../extra/Title";
import Button from "../../extra/Button";
import CoreValueDetails from "./CoreValueDetails";
function CoreValue() {
  const dispatch = useDispatch();

  const { category, isSkeleton } = useSelector((state) => state.category);

  useEffect(() => {
    // dispatch(categoryGet());
  }, []);

  return (
    <section id="CoreValues">
      <div className="container">
        <div className="mainCore cursor-pointer">
          <div className="row justify-content-center">
            <Corse
              icon={Core1}
              title={`Inclusivity and Team-work`}
              dec={`We value and respect the diversity and foster the culture of open communication and team work to get benefits of diverse skills and perspectives`}
            />
            <Corse
              icon={Core2}
              title={`Continuous Improvement`}
              dec={`We encourage and embrace continuous learning, growth opportunities and professional development among students and employees to evolve and enhance processes and to stay relevant in a fast-speed industry.`}
            />
            <Corse
              icon={Core3}
              title={`Infinite Flexibility Systems`}
              dec={`We enfold flexibility and adaptability to cope up quickly with students’ problems, customer feedback, market changes and emerging trends`}
            />
          </div>
        </div>
      </div>
      {/* <CoreValueDetails /> */}
    </section>
  );
}
export default CoreValue;

export const Corse = ({ icon, title, dec }) => {
  return (
    <div className="col-lg-4 col-sm-6 col-xsm-8 col-12 m20-bottom text-sm-start text-center">
      <div className="coreBox bg-light p20-x p30-y">
        <div className="coreIcon hw-lg-70 hw-55 m25-lg-bottom m15-bottom m0-sm m-auto">
          <img src={icon} alt="" />
        </div>
        <SmallTitle
          text={title}
          className={`courseTitle text-overflow-2 m10-bottom m20-top`}
        />
        <Description
          text={dec}
          className={`courseDec text-gray text-overflow-3`}
        />
        <div className="coreBtn">
          <Button
            className={`primeBtn m20-sm-top m10-top`}
            text={`Read More`}
          />
        </div>
      </div>
    </div>
  );
};

// {
//   category?.length > 0 ? category?.map((res) => {
//     return (
//       <div class='item'>
//         <div className="categoryItems" onClick={() => handleShowCategory(res._id)}>
//           <img src={baseURL + res.image} alt="" />
//         </div>
//         <p className='categoryName'>{res.categoryName}</p>
//       </div>
//     )
//   }) : [...Array(6)]?.map((res) => {
//     return (
//       <div class='item'>
//         <div className="categoryItems skeleton">
//         </div>
//         <p className='categoryName skeleton'></p>
//       </div>
//     )
//   })
// }
