import Slider from "./components/Slider";
import CoreValue from "./components/CoreValue/CoreValue";
import Header from "./globle/Header";
import { baseURL } from "./utils/config";
import AboutCompany from "./components/AboutCompany";
import OurCourses from "./components/OurCourses";
import OurBusiness from "./components/OurBusiness";
import AskQuery from "./components/AskQuery";
import Inquiry from "./components/Inquiry";
import CeoDetails from "./components/CeoDetails";
import EmployeeDetails from "./components/EmployeeDetails";
import Blog from "./components/Blog";
import Footer from "./globle/Footer";

const Home = () => {
  return (
    <div>
      <Header />
      <Slider />
      <CoreValue />
      <AboutCompany />
      <OurCourses />
      <OurBusiness />
      <AskQuery />
      <Inquiry />
      <CeoDetails />
      <EmployeeDetails />
      <Blog />
      <Footer />
    </div>
  );
};

export default Home;
