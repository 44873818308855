import React, { useCallback, useEffect, useState } from "react";
import Logo from "../../assets/images/logo.png";
import { Link } from "react-router-dom";
import $ from "jquery";
import Button from "../extra/Button";

const Header = () => {
  return (
    <>
      <MenuScript />
      <div className="mainHeader bg-light">
        <div className={`middleHeader bg-light`}>
          <div className="mainBgDiv w-100 d-flex align-items-center">
            <div className="leftBg w-lg-50 w-100">
              <div className="container">
                <div className="logo m-auto ">
                  <Link to="/">
                    <img src={Logo} alt="THE VINI" />
                  </Link>
                </div>
              </div>
            </div>
            <div className="rightBg w-lg-100 w-smm-50 w-100">
              <div className="topBg d-lg-block d-none">
                <div className="titleBar">
                  <div className="row w-100 align-items-center">
                    <div className="col-8 text-center m10-y">
                      <p className="fs-16">Best IT Buisness Company In India</p>
                    </div>
                    <div className="col-4 m10-y">
                      <p className="fs-16">
                        <div className="socialIcon d-flex">
                          <span className="hw-xl-40 hw-30 midBox">
                            <i class="fa-brands fa-facebook-f"></i>
                          </span>
                          <span className="hw-xl-40 hw-30 midBox">
                            <i class="fa-brands fa-twitter"></i>
                          </span>
                          <span className="hw-xl-40 hw-30 midBox">
                            <i class="fa-brands fa-instagram"></i>
                          </span>
                          <span className="hw-xl-40 hw-30 midBox">
                            <i class="fa-brands fa-pinterest"></i>
                          </span>
                          {/* <span>
                  <i class="fa-brands fa-youtube"></i>
                </span> */}
                        </div>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bottomBg bg-dark d-flex align-items-center justify-content-between">
                <div className="mainNavigation bg-black d-flex">
                  <div className="extraBox width-lg-40 width-20 bg-darkGray m30-lg-right m0-right p0-lg p36-y"></div>
                  <div className="backBond closeNav d-lg-none"></div>
                  <div className="startNav closeNav">
                    <div className="sideNavHead betBox p20 border-bottom-lightGray-1 d-lg-none">
                      <div
                        id="clodeBtn"
                        className="clodeBtn text-start cursor-pointer"
                      >
                        <i class="fa-solid fa-xmark"></i>
                      </div>
                      <Button
                        className={`primeBtn`}
                        bIcon={`fa-solid fa-phone`}
                        text={`Contact Us`}
                      />
                    </div>
                    <nav>
                      <ul className="mainMenu text-light d-flex">
                        <li>
                          <Link to={"#"}>
                            <span>Home</span>
                          </Link>
                        </li>
                        <li className="take">
                          <Link to={"#"}>
                            <span>About</span>
                            <span>
                              <i class="fa-solid fa-angle-down"></i>
                            </span>
                          </Link>
                          <ul className="subMenu subToggle">
                            <li>
                              <Link to={"#"}>
                                <span>About Me</span>
                              </Link>
                            </li>
                            <li>
                              <Link to={"#"}>
                                <span>About Us</span>
                              </Link>
                            </li>
                          </ul>
                        </li>
                        <li className="take">
                          <Link to={"#"}>
                            <span>Courses</span>
                            <span>
                              <i class="fa-solid fa-angle-down"></i>
                            </span>
                          </Link>
                          {/* Mega Menu */}
                          <div className="megaMenu subToggle">
                            <div className="row">
                              <div className="col">
                                <h5 className="megaTitle fw-600">
                                  DEVELOPMENT COURSES
                                </h5>
                                <ul>
                                  <li>
                                    <Link to={`#`}>
                                      Master In Full Stack Development
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to={`#`}>
                                      Master In Web Development
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to={`#`}>Advance Node JS</Link>
                                  </li>
                                </ul>
                                <h5 className="megaTitle fw-600 m20-top">
                                  DESIGN COURSES
                                </h5>
                                <ul>
                                  <li>
                                    <Link to={`#`}>Master In Web Design</Link>
                                  </li>
                                  <li>
                                    <Link to={`#`}>Master in UI/UX Design</Link>
                                  </li>
                                  <li>
                                    <Link to={`#`}>
                                      Advance Graphics Design
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to={`#`}>Advance React JS</Link>
                                  </li>
                                </ul>
                              </div>
                              <div className="col">
                                <h5 className="megaTitle fw-600">
                                  SoftwareScript courses
                                </h5>
                                <ul>
                                  <li>
                                    <Link to={`#`}>C Programming</Link>
                                  </li>
                                  <li>
                                    <Link to={`#`}>C++ Programming</Link>
                                  </li>
                                  <li>
                                    <Link to={`#`}>JS Programming</Link>
                                  </li>
                                  <li>
                                    <Link to={`#`}>Advance PHP</Link>
                                  </li>
                                  <li>
                                    <Link to={`#`}>Laravel</Link>
                                  </li>
                                </ul>
                                <h5 className="megaTitle fw-600 m20-top">
                                  TrendCraft Courses
                                </h5>
                                <ul>
                                  <li>
                                    <Link to={`#`}>Node JS</Link>
                                  </li>
                                  <li>
                                    <Link to={`#`}>React JS</Link>
                                  </li>

                                  <li>
                                    <Link to={`#`}>Socket.io</Link>
                                  </li>
                                </ul>
                              </div>
                              <div className="col">
                                <h5 className="megaTitle fw-600">
                                  QuickSkills Courses
                                </h5>
                                <ul>
                                  <li>
                                    <Link to={`#`}>Adob Photoshop</Link>
                                  </li>
                                  <li>
                                    <Link to={`#`}>Adobe Illustrator</Link>
                                  </li>
                                  <li>
                                    <Link to={`#`}>Adobe XD</Link>
                                  </li>
                                  <li>
                                    <Link to={`#`}>Figma</Link>
                                  </li>
                                  <li>
                                    <Link to={`#`}>
                                      CCC- Basic Computer Course
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to={`#`}>CorelDraw</Link>
                                  </li>
                                  <li>
                                    <Link to={`#`}>Web Hosting</Link>
                                  </li>
                                  <li>
                                    <Link to={`#`}>CSS/SASS/SCSS</Link>
                                  </li>
                                  <li>
                                    <Link to={`#`}>Logo Design</Link>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li className="take">
                          <Link to={"#"}>
                            <span>Technologies</span>
                            <span>
                              <i class="fa-solid fa-angle-down"></i>
                            </span>
                          </Link>
                        </li>
                        <li>
                          <Link to={"#"}>
                            <span>Blog</span>
                          </Link>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
                <div className="superBtn d-flex align-items-center justify-content-lg-start justify-content-between w-100 m0-lg-x m40-sm-x m20-smm-x m10-x">
                  <Button
                    className={`secondBtn m10-md-x hw-lg-40 hw-md-35 hw-xsm-30 hw-25 fs-lg-16 fs-xsm-14 fs-10 p0`}
                    icon={`fa-solid fa-magnifying-glass`}
                  />
                  <Button
                    className={`secondBtn m10-md-x d-lg-none hw-lg-40 hw-md-35 hw-xsm-30 hw-25 fs-lg-16 fs-xsm-14 fs-10 p0`}
                    icon={`fa-solid fa-user`}
                  />
                  <div
                    id="menuTaggle"
                    className="toggleBtn d-lg-none m10-md-x hw-lg-40 hw-md-35 hw-xsm-30 hw-25 fs-lg-16 fs-xsm-14 fs-10 midBox border-light-1 text-light rounded-1 cursor-pointer"
                  >
                    <i class="fa-solid fa-bars"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;

export const MenuScript = () => {
  useEffect(() => {
    // ================ Mobile menu toggale ==================

    checkWindowSize();

    // Attach the function to the window resize event
    $(window).resize(function () {
      checkWindowSize();
    });

    function checkWindowSize() {
      if ($(window).width() <= 992) {
        $(".startNav").addClass("mobileNav");
        $(".subToggle").hide();
      } else {
        $(".startNav").removeClass("mobileNav");
        $(".subToggle").show();
      }
    }

    // Main Menu
    const handleSidebar = () => {
      $(".startNav.mobileNav, .backBond").toggleClass("openNav closeNav");
    };

    $(
      "#menuTaggle, #clodeBtn, .backBond,.mobileNav .mainMenu li:not('.take')"
    ).on("click", handleSidebar);

    // Sub Menu & Mega Menu
    const handleSubSidebar = (event) => {
      $(".mobileNav .subToggle").not($(event.currentTarget).next()).slideUp();
      $(event.currentTarget).next().slideToggle();
    };
    $(".mobileNav .subToggle").hide();
    $(".mobileNav .mainMenu > li > a").on("click", handleSubSidebar);

    return () => {
      // ================ Mobile menu toggale ==================
      $(
        "#menuTaggle, #clodeBtn, .backBond,.mobileNav .mainMenu li:not('.take')"
      ).off("click", handleSidebar);
      $(".mobileNav  .mainMenu > li > a").off("click", handleSubSidebar);
    };
  }, []);

  return null;
};
