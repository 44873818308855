import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiInstance } from "../../api/axiosApi";

const initialState = {
  banner: [],
  isSkeleton: false,
};
export const bannerGet = createAsyncThunk("banner/bannerGet", async () => {
  return apiInstance.get("banner/bannerGet?type=web");
});

const banenrSlice = createSlice({
  name: "banenrSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // bannerGet
    builder.addCase(bannerGet.pending, (state, action) => {
      state.isSkeleton = true;
    });
    builder.addCase(bannerGet.fulfilled, (state, action) => {
      state.banner = action.payload.banner;
      state.isSkeleton = false;
    });
    builder.addCase(bannerGet.rejected, (state, action) => {
      state.isSkeleton = false;
    });
  },
});
export default banenrSlice.reducer;
