import React from "react";
import OwlCarousel from "react-owl-carousel";
import Emp1 from "../../assets/images/emp1.png";
import Emp2 from "../../assets/images/emp2.png";
import Emp3 from "../../assets/images/emp3.png";
import Emp4 from "../../assets/images/emp4.png";
import Emp5 from "../../assets/images/emp5.png";
import Title from "../extra/Title";

const EmployeeDetails = () => {
  return (
    <section id="EmployeeDetails" className="bg-light">
      <div className="startEmployeeDetails ">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-8 col-smm-10 col-12">
              <Title
                mainTitle={`Let’s Looks Our Global Projects`}
                titleDec={`Embark on a visual journey through the diverse tapestry of projects at Multikod Infosys & Advance Training. Our global initiatives span industries and continents, showcasing a commitment to excellence and innovation. From cutting-edge web development to transformative IT solutions, join us as we take pride in the impact and success stories woven into every project. Let's delve into a world where global challenges meet creative solutions, and where your vision becomes our global mission`}
                className={`text-center text-overflow-3`}
              />
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="employeeSlider m30-top">
            <OwlCarousel
              className="owl-theme"
              items={5}
              margin={20}
              center={true}
              loop
              nav={false}
              navClass={[
                "fa-solid fa-angles-left prev",
                "fa-solid fa-angles-right next",
              ]}
              autoplay={true}
              // animateIn={`animate__fadeInRightBig`}
              animateOut={`animate__fadeOutLeftBig`}
              dots={false}
              responsive={{
                0: {
                  items: 1,
                  center: false,
                },
                400: {
                  items: 2,
                  center: false,
                },
                600: {
                  items: 3,
                  center: true,
                },
                992: {
                  items: 4,
                  center: true,
                },
                1300: {
                  items: 5,
                  center: true,
                },
              }}
            >
              <SingleEmployee
                image={Emp1}
                title={`Tech Solutions`}
                dec={`Full Stack Development`}
              />
              <SingleEmployee
                image={Emp2}
                title={`Working Process`}
                dec={`Developers and Designers`}
              />
              <SingleEmployee
                image={Emp3}
                title={`Our Mission`}
                dec={`Reliability and Quality`}
              />
              <SingleEmployee
                image={Emp4}
                title={`Platform Integration `}
                dec={`Web and Mobile Applications`}
              />
              <SingleEmployee
                image={Emp5}
                title={`Innovative Ideas`}
                dec={`AI Integration`}
              />
            </OwlCarousel>
          </div>
        </div>
      </div>
    </section>
  );
};

export default EmployeeDetails;

export const SingleEmployee = ({ image, title, dec }) => {
  return (
    <div className="item">
      <div className="employeeList">
        <div className="employeeImage height-xxl-380 height-lg-350 height-md-360 height-sm-300 height-smm-280 height-xsm-300 height-mini-390 height-300">
          <img src={image} alt="" height={`100%`} />
        </div>
        <div className="employeeText text-center p15-x ">
          <div className="innerEmpBox bg-light rounded-2">
            <div className="ceoName fw-bold p10-y fs-md-18 fs-sm-16 fs-14 trans-3">
              {title}
            </div>
            <div className="ceoPosition text-gray m10-bottom fs-md-16 fs-sm-14 fs-12">
              {dec}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
