import React from "react";
import BusinessImage from "../../assets/images/businessProcess.png";
import Title from "../extra/Title";

const OurBusiness = () => {
  return (
    <section id="OurBusiness" className="p50-y">
      <div className="startOurBusiness d-lg-flex">
        <div className="container-lg-fuild container p0-lg">
          <div className="row w-100 h-lg-100 m-lg-0 m-auto">
            <div className="col-12 p0">
              <div className="ourBusinessImage h-lg-100">
                <img src={BusinessImage} alt="" className="h-lg-100" />
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row justify-content-lg-start justify-content-center">
            <div className="col-xxl-8 col-xsm-11 col-12">
              <div className="ourBusinessDetails p20">
                <Title
                  smallName={`Buisness Process`}
                  mainTitle={`Design & Development`}
                  titleDec={`Welcome to Multikod Infosys & Advance Training, where we specialize in revolutionizing businesses through innovative Business Process Design & Development solutions. Our comprehensive suite of services is meticulously crafted to elevate your organization's efficiency, agility, and overall performance`}
                  className={`text-lg-start text-center`}
                />
                <div className="businessProcess m30-top">
                  <div className="row justify-content-center">
                    <div className="col-sm-6 col-smm-8 col-mini-10 col-12">
                      <div className="businessPorps">React web development</div>
                    </div>
                    <div className="col-sm-6 col-smm-8 col-mini-10 col-12">
                      <div className="businessPorps"> UX/UI design</div>
                    </div>
                    <div className="col-sm-6 col-smm-8 col-mini-10 col-12">
                      <div className="businessPorps">
                        E-commerce development
                      </div>
                    </div>
                    <div className="col-sm-6 col-smm-8 col-mini-10 col-12">
                      <div className="businessPorps"> Responsive design</div>
                    </div>
                    <div className="col-sm-6 col-smm-8 col-mini-10 col-12">
                      <div className="businessPorps">Web Development</div>
                    </div>
                    <div className="col-sm-6 col-smm-8 col-mini-10 col-12">
                      <div className="businessPorps">UI / UX Design</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurBusiness;
