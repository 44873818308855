import React, { useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { useDispatch, useSelector } from "react-redux";
import { bannerGet } from "../../redux/slice/bannerSlice";
import { baseURL } from "../utils/config";
import Poster1 from "../../assets/images/poster1.png";
import Poster2 from "../../assets/images/poster2.jpg";
import Wawe from "../../assets/images/wawe.png";
import Button from "../extra/Button";
const Slider = () => {
  const dispatch = useDispatch();
  const { banner, isSkeleton } = useSelector((state) => state.banner);
  // const isSkeleton = false;
  useEffect(() => {
    dispatch(bannerGet());
  }, []);

  return (
    <>
      <section id="Slider" className="p0">
        <div className="mainSlider bg-light">
          <div className="container-fluid p0">
            <OwlCarousel
              className="owl-theme"
              items={1}
              loop
              nav
              navClass={[
                "fa-solid fa-angles-left prev",
                "fa-solid fa-angles-right next",
              ]}
              autoplay={true}
              // animateIn={`animate__fadeInRightBig`}
              animateOut={`animate__fadeOutLeftBig`}
            >
              {!isSkeleton
                ? banner?.map((res) => {
                    return (
                      <div class="item">
                        <div
                          className="bannerImage d-flex align-items-center"
                          style={{
                            backgroundImage: `url(${
                              baseURL + res?.image.replace(/\\/g, "/")
                            })`,
                          }}
                        >
                          <div className="bgBlackPart"></div>
                          <div className="container">
                            <div className="startBannerText m100-md-bottom m30-bottom">
                              <div className="row align-items-center h-100">
                                <div className="col-smm-7 col-6">
                                  <div className="bannerText text-light">
                                    <div className="bannerHead m10-sm-bottom m3-bottom fs-xxl-30 fs-xl-25 fs-md-20 fs-sm-15 fs-smm-12 fs-10 text-overflow-1">
                                      {res?.miniTitle}
                                    </div>
                                    <div
                                      className="bannerTitle fw-600 fsl-xxl-130 fsl-xl-100 fsl-md-60 fsl-sm-50 fsl-smm-40 fsl-25 text-overflow-2"
                                      title={res?.bigTitle}
                                    >
                                      {res?.bigTitle}
                                    </div>
                                    <div
                                      className="bannerDec m10-sm-top m3-top fs-xxl-20 fs-md-14 fs-smm-12 fs-10 text-overflow-2"
                                      title={res?.description}
                                    >
                                      {res?.description}
                                    </div>
                                    {/* <Button
                                      className={`secondBtn m20-sm-top m10-top`}
                                      text={`Read More`}
                                    /> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                : [...Array(3)]?.map((res) => {
                    return (
                      <div class="item">
                        <div className="bannerImage skeleton"></div>
                      </div>
                    );
                  })}
            </OwlCarousel>
          </div>
        </div>
        <div className="waweStyle">
          <img src={Wawe} />
        </div>
      </section>
    </>
  );
};

export default Slider;
