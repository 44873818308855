import React from "react";

function Title(props) {
  const { className, smallName, mainTitle, titleDec } = props;
  return (
    <div className={`${className}`}>
      {smallName && <SmallTitle text={smallName} className={`text-second`} />}
      {mainTitle && (
        <MainTitle
          text={mainTitle}
          className={`m10-top ${smallName && "m10-bottom"} `}
        />
      )}
      {titleDec && <Description text={titleDec} />}
    </div>
  );
}

export default Title;

export const MainTitle = ({ text, className }) => {
  return (
    <div className={`fw-600 fs-xl-40 fs-md-34 fs-sm-30 fs-26 ${className}`}>
      {text}
    </div>
  );
};
export const SmallTitle = ({ text, className }) => {
  return (
    <div className={`fw-bold fs-md-20 fs-sm-18 fs-16 ${className}`}>{text}</div>
  );
};
export const Description = ({ text, className }) => {
  return <div className={`text-gray  fs-md-14 fs-sm-12 fs-12 ${className}`}>{text}</div>;
};
