import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiInstance } from "../../api/axiosApi";

const initialState = {
  category: [],
  isSkeleton: false
};
export const categoryGet = createAsyncThunk("category/show", async () => {
  return apiInstance.get("category/show");
});

const categorySlice = createSlice({
  name: "category",
  initialState,
  reducers: {

  },
  extraReducers: (builder) => {
    // categoryGet
    builder.addCase(categoryGet.pending, (state, action) => {
      state.isSkeleton = true;
    });
    builder.addCase(categoryGet.fulfilled, (state, action) => {
      state.category = action.payload.category;
      state.isSkeleton = false;

    });
    builder.addCase(categoryGet.rejected, (state, action) => {
      state.isSkeleton = false;
    });

  },
});
export default categorySlice.reducer;

