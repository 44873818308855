import React, { useEffect } from "react";
import Title from "../extra/Title";
import $ from "jquery";
import queryBoy from "../../assets/images/askQuery.gif";

const AskQuery = () => {
  return (
    <section id="AskQuery" className="p100-top p5-bottom bg-light">
      <QueryScript />
      <div className="container">
        <div className="startAskQuery">
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-8 col-smm-10 col-12">
              <Title
                mainTitle={`Frequently Asked Questions`}
                titleDec={`Welcome to our Frequently Asked Questions (FAQ) section, where we address common queries to provide you with clear and concise information about our products, services, and policies.`}
                className={` text-center`}
              />
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-md-6 col-12 order-md-0 order-1">
              <ul className="mainQuery font-second">
                <SingleQuery
                  title={`What is the future in IT?`}
                  dec={`As we all know, AI is a new generation in the IT field, the demand of IT professionals is increasing day by day for newer innovations.`}
                />
                <SingleQuery
                  title={`Can I customize my course selection?`}
                  dec={`As flexibility is our core value, we provide you customization in your interested course keeping the demand of the field in mind.`}
                />
                <SingleQuery
                  title={`Do you have Training program for freshers?`}
                  dec={`Yes, we hire freshers on the basis of internship programs to enhance their skills more favorable to our firm.`}
                />
                <SingleQuery
                  title={`What should I do if I encounter technical issues while using your service?`}
                  dec={`You may immediately contact us through our contact number or E-mail address and we assure that we will never disappoint you.`}
                />
              </ul>
            </div>
            <div className="col-md-6 col-12 order-md-1">
              <div className="queryGif">
                <img src={queryBoy} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AskQuery;

export const SingleQuery = (props) => {
  const { title, dec } = props;
  return (
    <li className="">
      <div className="mainQuerytitle betBox align-items-baseline border-bottom cursor-pointer fs-lg-16 fs-xsm-14 fs-12">
        <p className="m15-bottom m20-top text-capitalize text-second fw-500">
          {title}
        </p>
        <div className="queryIcon text-second">
          <i class="ri-add-circle-line"></i>
        </div>
      </div>
      <ul className="subQuery p0 m0-y">
        <li>
          <p className="p10-y fs-lg-16 fs-xsm-14 fs-12">
            {dec}
            {/* Lorem ipsum, dolor sit amet consectetur adipisicing elit. Nam
            aperiam et eveniet, ipsam officiis corrupti dolorem ipsum earum
            consequatur quibusdam? */}
          </p>
        </li>
      </ul>
    </li>
  );
};

export const QueryScript = ({ data }) => {
  useEffect(() => {
    // ================ Query Toggle ==================

    const handleQueryToggle = (event) => {
      const target = event.currentTarget;
      $(".subQuery").not($(target).next(".subQuery")).slideUp();
      $(".mainQuerytitle .queryIcon i")
        .not($(target).children(".queryIcon").children("i"))
        .removeClass("ri-indeterminate-circle-fill")
        .addClass("ri-add-circle-line");
      $(target)
        .children(".queryIcon")
        .children("i")
        .toggleClass("ri-indeterminate-circle-fill ri-add-circle-line");
      $(target).next(".subQuery").slideToggle();
    };
    $(".subQuery").hide();
    $(".mainQuery > li > .mainQuerytitle").on("click", handleQueryToggle);

    return () => {
      $(".mainQuery > li > .mainQuerytitle").off("click", handleQueryToggle);
    };
  }, []);

  return null;
};
