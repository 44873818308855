import logo from './5779877.png';
import './App.css';
import "./assets/css/style/style.css";
import "./assets/css/cutsom/custom.css";
import "./assets/css/default/default.css";
import Home from './pages/Home';
import Header from './pages/globle/Header';
import Footer from './pages/globle/Footer';
import { Route, Routes, useLocation } from 'react-router-dom';
import Login, { Signup } from './pages/globle/Login';
import { useDispatch } from 'react-redux';
import { useEffect, useRef } from 'react';
import { setOldAdmin } from './redux/slice/userAuthSlice';
import { setToken } from './pages/utils/setAuth';
import Admin from './Admin';
import 'animate.css';
import { ToastContainer } from 'react-toastify';
import { setToast } from './pages/extra/toast';

function App() {

  const dispatch = useDispatch();
  const key = sessionStorage.getItem("key");
  const token = sessionStorage.getItem("token");

  useEffect(() => {
    if (!token && !key) return;
    dispatch(setOldAdmin(token))
  }, [setToken, key]);
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);


  return (

    <div className='mainWebPages'>
      <Routes>
        <Route path="/*" element={<Admin />} />
        {/* <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} /> */}
      </Routes>

      <ToastContainer />
    </div>

  );
}

export default App;
