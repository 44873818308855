import Footer1 from "../../assets/images/footer1.png";
import Footer2 from "../../assets/images/footer2.png";
import Button from "../extra/Button";
import Logo from "../../assets/images/White-Logo.png";
import { Description, SmallTitle } from "../extra/Title";

const Footer = () => {
  return (
    <section id="Footer">
      <div className="container">
        <div className="mainFooter text-lg-start text-center fs-lg-16 fs-md-14 fs-12">
          <div className="row justify-content-sm-between justify-content-center">
            <div className="col-lg-3 col-sm-12 col-12 m0-lg-bottom m60-bottom thisCardFooter">
              <div className="footerContectCard bg-light text-dark rounded-2 overflow-hidden">
                <div className="footerLogo text-center bg-dark p10-y ">
                  <img src={Logo} alt="" className="w-logo" />
                </div>
                <div className="footerText p20-x p40-y">
                  <SmallTitle text={`About Company`} className={`m10-bottom`} />
                  <Description
                    text={`Elevate your digital presence with Multikod Infosys & Advance Training, where innovation meets functionality. As a leading player in the tech sphere, we specialize in crafting dynamic and user-centric web solutions tailored to meet the unique needs of your business. `}
                    className={`m10-bottom text-overflow-5`}
                  />
                  <div className="socialIcon">
                    <span className="hw-xl-40 hw-30 midBox">
                      <i class="fa-brands fa-facebook-f"></i>
                    </span>
                    <span className="hw-xl-40 hw-30 midBox">
                      <i class="fa-brands fa-twitter"></i>
                    </span>
                    <span className="hw-xl-40 hw-30 midBox">
                      <i class="fa-brands fa-instagram"></i>
                    </span>
                    <span className="hw-xl-40 hw-30 midBox">
                      <i class="fa-brands fa-pinterest"></i>
                    </span>
                    {/* <span>
                  <i class="fa-brands fa-youtube"></i>
                </span> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-sm-3 col-mini-8 col-12 m0-sm-bottom m40-bottom">
              <div className="m0-bottom">
                <h5>Menu</h5>
                <ul className="aboutUs m0 p0 m20-top">
                  <li>Home</li>
                  <li>About</li>
                  <li>Courses</li>
                  <li>Technologies</li>
                  <li>Blog</li>
                  <li>Contact</li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-sm-4 col-mini-6 col-10 m0-sm-bottom m40-bottom">
              <div className="m0-bottom">
                <h5>About</h5>
                <ul className="aboutUs text-sm-start m0 p0 m20-top">
                  <li>
                    <div className="footerServies d-flex flex-sm-row flex-column justify-content-sm-start justify-content-center">
                      <div className="serviceImage hw-lg-75 hw-60 m10-sm-right m-auto">
                        <img src={Footer1} alt="" />
                      </div>
                      <div className="serviceText m0-sm-top m10-top">
                        Technology is a powerful revolution.
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="footerServies d-flex flex-sm-row flex-column justify-content-sm-start justify-content-center">
                      <div className="serviceImage hw-lg-75 hw-60 m10-sm-right m-auto">
                        <img src={Footer2} alt="" />
                      </div>
                      <div className="serviceText m0-sm-top m10-top">
                        IT Tech: A Revolution Unleashed
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-sm-5 col-mini-8 col-12 m0-sm-bottom m40-bottom">
              <h5>Contact Us</h5>
              <div className="d-flex flex-sm-row flex-column align-items-center m20-y text-sm-start justify-content-sm-start justify-content-center m0-md-bottom m10-bottom">
                <div className="contactIcon hw-lg-40 hw-xsm-35 hw-30 border-second-1 rounded-5 midBox text-second m10-right">
                  <i class="fa-solid fa-phone"></i>
                </div>
                <div className="contactText">
                  <div>+91 886662 5957</div>
                  <div>+91 63581 63236</div>
                </div>
              </div>
              <div className="d-flex flex-sm-row flex-column align-items-center m20-y text-sm-start justify-content-sm-start justify-content-center m0-md-bottom m10-bottom">
                <div className="contactIcon hw-lg-40 hw-xsm-35 hw-30 border-second-1 rounded-5 midBox text-second m10-right">
                  <i class="fa-solid fa-envelope"></i>
                </div>
                <div className="contactText">
                  <div>multikodit@gmail.com</div>
                </div>
              </div>
              <div className="d-flex flex-sm-row flex-column align-items-center m20-y text-sm-start justify-content-sm-start justify-content-center m0-md-bottom m10-bottom">
                <div className="contactIcon hw-lg-40 hw-xsm-35 hw-30 border-second-1 rounded-5 midBox text-second m10-right">
                  <i class="fa-solid fa-location-dot"></i>
                </div>
                <div className="contactText">
                  <div>
                    501, Pavitra Point, Opp. BBC, Near Savaliya Circle, Yogi
                    Chowk Road, Surat 395010
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="aboutFooter m20-top">
            <div className="row justify-content-center">
              <div className="col-sm-6 col-12 text-sm-start text-center">
                Copyright © 2024 Multikod Infosys & Advance Training. All Rights Reserved
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Footer;
