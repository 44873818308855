import React from "react";
import Pic1 from "../../assets/images/company1.png";
import Pic2 from "../../assets/images/company2.png";
import Pic3 from "../../assets/images/company3.png";
import Pic4 from "../../assets/images/company4.png";
import Light from "../../assets/images/light.png";
import Title from "../extra/Title";
import Input from "../extra/Input";
import Button from "../extra/Button";

const AboutCompany = () => {
  return (
    <section id="aboutCompany" className="p100-md-y p40-y">
      <div className="container">
        <div className="startAboutCompany">
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-8 col-11">
              <div className="companyPhotos">
                <div className="abComImg abComImg1">
                  <img src={Pic1} alt="" />
                </div>
                <div className="abComImg abComImg3">
                  <img src={Pic3} alt="" className="h-100" />
                </div>
                <div className="abComImg abComImg2">
                  <img src={Pic2} alt="" />
                </div>
                <div className="abComImg abComImg4">
                  <img src={Pic4} alt="" />
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-8 col-11">
              <div className="companyDetails p40-lg-x p10-x p0-lg-y p20-y">
                <Title
                  smallName={`WHY CHOOSE US?`}
                  mainTitle={`Elevate Your Learning Experience`}
                  titleDec={`We offer exclusive and inclusive facilities. On the other hand, we have vowed to dedicate our full capacity in providing it.`}
                  className={`text-lg-start text-center`}
                />

                <div className="companyGoal m20-top">
                  <Input
                    type={`checkbox`}
                    label={`Industry Expert Trainers`}
                    checked={true}
                    upClassName={`m20-bottom`}
                  />
                  <Input
                    type={`checkbox`}
                    label={`Office Space & Personalized Attention`}
                    checked={true}
                    upClassName={`m20-bottom`}
                  />
                  <Input
                    type={`checkbox`}
                    label={`Advance Training + 2 Months Internship`}
                    checked={true}
                    upClassName={`m20-bottom`}
                  />
                  <Input
                    type={`checkbox`}
                    label={`Career Counseling, Interview Support & Personality Development`}
                    checked={true}
                    upClassName={`m20-bottom`}
                  />
                  <Input
                    type={`checkbox`}
                    label={`100% Learning Guarantee & Live Project`}
                    checked={true}
                    upClassName={`m20-bottom`}
                  />
                </div>

                <div className="companyView bg-light p20-x p15-y rounded-2 fs-sm-16 fs-xsm-14 fs-12 d-flex align-items-center m30-top">
                  <div className="viewImg hw-md-45 hw-35 m20-right">
                    <img src={Light} alt="" />
                  </div>
                  <div className="viewText fw-bold">
                    Exclusive Facilities, Inclusive Commitment, and Unparalleled
                    Dedication
                  </div>
                </div>

                <div className="companyInquery m30-top flex-wrap d-flex align-items-center justify-content-lg-start justify-content-center fs-sm-16 fs-xsm-14 fs-12">
                  <Button
                    className={`primeBtn  m20-bottom`}
                    text={`Contact Us`}
                  />
                  <div className="companyContact d-flex align-items-center m30-left  m20-bottom">
                    <div className="contactIcon hw-lg-40 hw-xsm-35 hw-30 border-second-1 rounded-5 midBox text-second m10-right">
                      <i class="fa-solid fa-phone"></i>
                    </div>
                    <div className="contactText fw-bold">
                      <div>Call Everybody</div>
                      <div>+91 886662 5957</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutCompany;
