import React from "react";
import Man from "../../assets/images/man.png";
import Title from "../extra/Title";
import Input, { Textarea } from "../extra/Input";
import Button from "../extra/Button";
import GlobleImage from "../../assets/images/globleBG.png";

const Inquiry = () => {
  return (
    <section id="Inquiry" className="bg-light p0-top">
      <div
        className="globleBg"
        style={{ backgroundImage: `url(${GlobleImage})` }}
      ></div>
      <div className="container">
        <div className="startInquiry p80-lg-top p40-top p30-x  bg-lightBlue">
          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-6 col-8 order-md-0 order-1 m0 m60-lg-top d-flex align-items-end">
              <div className="inquiryMan">
                <img src={Man} alt="" />
              </div>
            </div>
            <div className="col-lg-8 col-md-6 col-12 order-md-1">
              <Title
                smallName={` `}
                mainTitle={`Inquiry Form`}
                titleDec={`Do you want to engage with us? (NOTE: We assure you of the privacy of data.)`}
                className={`text-lg-start text-center`}
              />
              <form id="inquiryForm" className="m30-lg-top m10-top">
                <div className="row">
                  <div className="col-lg-6 col-md-12 col-sm-6 col-12 ">
                    <Input
                      type={`text`}
                      id={`text`}
                      placeholder={`Your Name`}
                    />
                  </div>
                  <div className="col-lg-6 col-md-12 col-sm-6 col-12 ">
                    <Input
                      type={`text`}
                      id={`text`}
                      placeholder={`Email Address`}
                    />
                  </div>
                  <div className="col-lg-6 col-md-12 col-sm-6 col-12 ">
                    <Input
                      type={`text`}
                      id={`text`}
                      placeholder={`Your Phone`}
                    />
                  </div>
                  <div className="col-lg-6 col-md-12 col-sm-6 col-12 ">
                    <Input
                      type={`text`}
                      id={`text`}
                      placeholder={`Your Subject`}
                    />
                  </div>
                  <div className="col-12">
                    <Textarea id={`address`} placeholder={`Address`} row={4} />
                  </div>
                  <div className="col-12 m30-lg-top  d-flex  justify-content-lg-start justify-content-center">
                    <Button
                      type={`button`}
                      className={`primeBtn`}
                      text={`Send Message`}
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Inquiry;
