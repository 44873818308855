import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiInstance } from "../../api/axiosApi";
import {jwtDecode} from "jwt-decode";
import { SetDevKey } from "../../pages/utils/setAuth";
import { key } from "../../pages/utils/config";
import { setToast } from "../../pages/extra/toast";
import { clearFormFields } from "../../pages/utils/fuction";


const initialState = {
  loginUser: {},
  isAuth: false,
  isSkeleton: false
};
export const login = createAsyncThunk("user/login", async (payload) => {
  return apiInstance.post("user/login", payload);
});
export const updateUser = createAsyncThunk("user/updateUser", async (payload) => {
  return apiInstance.patch(`user/updateUser?userId=${payload.userId}`, payload.formData);
});
export const addAddress = createAsyncThunk("user/addAddress", async (payload) => {
  return apiInstance.patch(`user/addAddress?userId=${payload.userId}`, payload);
});
export const updateAddress = createAsyncThunk("user/updateAddress", async (payload) => {
  return apiInstance.put(`user/updateAddress?userId=${payload.userId}&addressId=${payload.addressId}`, payload);
});
export const deleteAddress = createAsyncThunk("user/deleteAddress", async (payload) => {
  return apiInstance.delete(`user/deleteAddress?userId=${payload.userId}&addressId=${payload.addressId}`, payload);
});


const userAuthSlice = createSlice({
  name: "authSlice",
  initialState,
  reducers: {
    setOldAdmin(state, action) {
      let token_ = JSON.parse(action.payload);
      state.loginUser = token_;
      state.isAuth = true;
      SetDevKey(key);
    },
    logout(state, action) {
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("key");
      sessionStorage.removeItem("isAuth");
      state.loginUser = {};
      state.isAuth = false;

    }
  },
  extraReducers: (builder) => {
    // User Login
    builder.addCase(login.pending, (state, action) => {
      state.isSkeleton = true;
    });
    builder.addCase(login.fulfilled, (state, action) => {
      console.log("state", action.payload.status);
      if (action.payload.status) {
        let token_ = jwtDecode(action.payload.token);
        state.loginUser = token_;
        state.isAuth = true;
        state.isSkeleton = false;

        SetDevKey(key);
        sessionStorage.setItem("token", token_ ? JSON.stringify(token_) : undefined);
        sessionStorage.setItem("key", key ? key : undefined);
        sessionStorage.setItem("isAuth", true);
        setToast("success", action.payload.message)
      } else {
        setToast("error", action.payload.message)
      }

    });
    builder.addCase(login.rejected, (state, action) => {
      state.isSkeleton = false;
    });

    builder.addCase(updateUser.fulfilled, (state, action) => {
      if (action?.payload?.status) {
        sessionStorage.setItem("token", action.payload.user ? JSON.stringify(action.payload.user) : undefined);
        state.loginUser = { ...state.loginUser, ...action.payload.user };
        setToast("success", action.payload.message)
      } else {
        setToast("error", action.payload.message)
      }
    });
    builder.addCase(addAddress.fulfilled, (state, action) => {
      if (action?.payload?.status) {
        sessionStorage.setItem("token", action.payload.user ? JSON.stringify(action.payload.user) : undefined);
        state.loginUser = { ...state.loginUser, ...action.payload.user };
        clearFormFields("checkoutForm")
        setToast("success", action.payload.message)
      } else {
        setToast("error", action.payload.message)
      }
    });

    builder.addCase(updateAddress.fulfilled, (state, action) => {
      if (action?.payload?.status) {
        sessionStorage.setItem("token", action.payload.user ? JSON.stringify(action.payload.user) : undefined);
        state.loginUser = { ...state.loginUser, ...action.payload.user };
        clearFormFields("checkoutForm")
        setToast("success", action.payload.message)
      } else {
        setToast("error", action.payload.message)
      }
    });

    builder.addCase(deleteAddress.fulfilled, (state, action) => {
      if (action?.payload?.status) {
        sessionStorage.setItem("token", action.payload.user ? JSON.stringify(action.payload.user) : undefined);
        state.loginUser = { ...state.loginUser, ...action.payload.user };
        setToast("success", action.payload.message)
      } else {
        setToast("error", action.payload.message)
      }
    });

  },
});
export default userAuthSlice.reducer;
export const { setOldAdmin, logout } = userAuthSlice.actions

